body,
html {
  min-height: 100%;
}

.particle-canvas {
  z-index: -1;
  min-height: 100vh;
}

/**
 * 2k and 4k resolution scaling
 */
.btn {
  @media (min-width: 2000px) {
    font-size: 1.4rem;
  }

  @media (min-width: 3000px) {
    font-size: 1.8rem;
  }
}

.bottom-text {
  z-index: 1;
  position: fixed;
  bottom: 0;
  left: 0;
  color: #fff;
  font-size: 0.75rem;
  margin: 0;
  padding-left: 0.5rem;
  padding-bottom: 0.5rem;
  opacity: 0.75;
}
