.circle-stat {
  display: block;
  width: 130px;
  height: 130px;

  &__bg {
    fill: none;
    stroke: $light;
    stroke-width: 0.5;
  }

  &__circle {
    fill: none;
    stroke-width: 2.8;
    stroke-linecap: round;
    animation: progress 1s ease-out forwards;
    stroke: $success;

    &--success {
      stroke: $success;
    }

    &--warning {
      stroke: $warning;
    }

    &--danger {
      stroke: $danger;
    }

    &--secondary {
      stroke: $secondary;
    }
  }

  &__percentage,
  &__findings {
    fill: $success;
    font-family: sans-serif;
    font-size: 0.43em;
    text-anchor: middle;

    &--success {
      fill: $success;
    }

    &--warning {
      fill: $warning;
    }

    &--danger {
      fill: $danger;
    }

    &--secondary {
      fill: $secondary;
    }
  }

  &__findings {
    font-size: 0.325rem;
  }

  &__wrap {
    width: 136px;
    margin: 0 auto;
  }

  &__inner-wrap {
    width: 130px;
    position: relative;
  }

  p {
    font-size: 0.8rem;
  }
}

.circle-stat__percentage--months {
  font-size: 0.3rem;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}
