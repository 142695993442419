// Custom.scss

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import 'node_modules/bootstrap/scss/functions';

// 2. Include any default variable overrides here
$blue-dark: #03265d;
$cyan: #05a6d9;
$green: #64fb5a;
$yellow: #f6bf07;
$red: #fc3e5e;

$primary: $blue-dark;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;

// 3. Include remainder of required Bootstrap stylesheets
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/utilities';

// 4. Include any optional Bootstrap components as you like
@import 'node_modules/bootstrap/scss/root';
@import 'node_modules/bootstrap/scss/reboot';
@import 'node_modules/bootstrap/scss/type';
@import 'node_modules/bootstrap/scss/images';
@import 'node_modules/bootstrap/scss/containers';
@import 'node_modules/bootstrap/scss/grid';
@import 'node_modules/bootstrap/scss/tables';
@import 'node_modules/bootstrap/scss/forms';
@import 'node_modules/bootstrap/scss/modal';
@import 'node_modules/bootstrap/scss/close';
@import 'node_modules/bootstrap/scss/alert';
@import 'node_modules/bootstrap/scss/buttons';
@import 'node_modules/bootstrap/scss/button-group';
@import 'node_modules/bootstrap/scss/badge';
@import 'node_modules/bootstrap/scss/transitions';
@import 'node_modules/bootstrap/scss/nav';
@import 'node_modules/bootstrap/scss/navbar';
@import 'node_modules/bootstrap/scss/utilities/api';

// 5. Add additional custom code here
