.info-btn {
  top: 84px;
  left: 50%;
  margin-left: -14px;
}

.info-modal-container {
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  height: 100vh;
  justify-content: center;
  z-index: 7;

  @include media-breakpoint-up(xl) {
    position: absolute;
    top: auto;
  }

  @media (min-width: 2000px) {
    transform: scale(1.25);
  }

  @media (min-width: 3000px) {
    transform: scale(1.4);
  }
}

.info-modal {
  position: absolute;
  width: calc(100% - 2rem);
  z-index: 10;
  background: $primary;
  border: 5px solid $info;
  border-radius: 15px;
  padding: 1rem;
  height: max-content;

  &__close {
    float: right;
    cursor: pointer;
  }

  @include media-breakpoint-up(xl) {
    width: 460px;
    margin-left: 1.5rem;
  }

  &__title {
    text-align: center;
    color: $info;
    font-size: 1.2rem;
    padding: 0 0.2rem;
    padding-right: 1.25rem;
  }

  p {
    font-size: 0.875rem;
  }

  &__backdrop {
    z-index: 6;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba($primary, 0.7);
  }

  &__inner {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 2.5rem;
    pointer-events: none;

    @include media-breakpoint-up(xl) {
      width: 66.6667%;
      align-items: center;
      padding-top: 0;
    }
  }

  &__circle {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    flex: 0 0 28px;

    &-copy {
      font-size: 0.75rem;
      color: #bac4d3;
    }
  }
}
