.dashboard-logo {
  padding-top: 44px;
  padding-left: 0;

  @include media-breakpoint-up(md) {
    padding-left: 50px;
  }
}

.dashboard-graph {
  border-bottom: 1px solid #4e678d;

  &__item {
    height: 85px;
  }

  &__text {
    font-size: 0.875rem;

    @media (min-width: 2000px) {
      font-size: 1rem;
    }
  }
}

.year-dropdown {
  margin-bottom: 0.3rem;
  position: relative;

  .years-list {
    position: absolute;
    background: #03265d;
    border-radius: 5px;
    width: 100%;
    border: 1px solid #fff;

    div {
      padding: 0.2rem;
      border-bottom: 1px solid #fff;
    }

    div:last-child {
      border-bottom: none;
    }

    div:hover {
      background-color: #1561db;
    }
  }

  .btn {
    background: transparent;
    border: 1px solid #fff;
  }

  .btn:after {
    display: inline-block;
    margin-left: 0.255rem;
    vertical-align: 0.255rem;
    content: '';
    border-top: 0.3rem solid;
    border-right: 0.3rem solid transparent;
    border-bottom: 0;
    border-left: 0.3rem solid transparent;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      text-align: center;
      padding: 0.25rem 0.5rem;
    }

    li:hover {
      color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
    }
  }
}

@mixin on-circle($item-count, $circle-size, $item-size) {
  position: relative;
  width: $circle-size;
  height: $circle-size;
  padding: 0;
  border-radius: 50%;
  list-style: none;

  > * {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: $item-size;
    height: $item-size;
    margin: -($item-size / 2);

    $angle: (360 / $item-count);
    $rot: 0;

    @for $i from 1 through $item-count {
      &:nth-of-type(#{$i}) {
        transform: rotate($rot * 1deg) translate($circle-size / 2) rotate($rot * -1deg);
      }

      $rot: $rot + $angle;
    }
  }
}

.circle-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 2rem;
  padding-left: 0;
  list-style: none;

  @include media-breakpoint-up(lg) {
    @include on-circle($item-count: 10, $circle-size: 40rem, $item-size: 6rem);
    display: block;
    margin: 4rem auto 6rem;
  }

  @include media-breakpoint-up(xl) {
    margin-bottom: 0;
    margin-top: 0;
  }

  @media (min-width: 2000px) {
    transform: scale(1.4);
  }

  @media (min-width: 3000px) {
    transform: scale(2);
  }

  li {
    margin: 0 auto;

    @include media-breakpoint-up(lg) {
      margin: -3rem;
    }
  }
}

.circle-center {
  width: 100%;
  padding: 0 2rem 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;

  @include media-breakpoint-up(lg) {
    transform: none !important;
    top: 45%;
    left: 36%;
    width: 50%;
    padding: 0;
  }

  p {
    margin-bottom: 0.5rem;
  }

  h2 {
    font-weight: 700;
    padding: 0 2rem;
  }

  .quarter-switch {
    display: flex;
    justify-content: center;
  }

  .quarter-button {
    width: 40px;
    height: 40px;
    border: 3px solid #fff;
    border-radius: 20px;
    padding-top: 5px;
    font-weight: 600;
    cursor: pointer;
    margin: 0 5px;
    transition: all 0.3s;

    &.active {
      color: #05a6d9;
      border-color: #05a6d9;
    }
  }

  .quarter-button:hover {
    color: $blue-dark;
    background-color: #fff;
  }
}

.kpi-title {
  font-size: 0.8rem;
  color: #fff;
}

.graph-legend {
  margin-top: 1rem;
  margin-bottom: 1.25rem;
}

.graph-legend-container {
  max-width: 600px;
  margin: 0 auto;
}

.graph-legend-item {
  transform: rotate(30deg);
  white-space: nowrap;
}

.loader {
  display: flex;
  justify-content: center;

  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }

  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }

  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }

  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }

  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

.tabs {
  margin-top: 1rem;

  .tab {
    &.active {
      background: #fff;
      color: #03265d;
    }
  }
}

.kpi-section {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.header-container {
  @include media-breakpoint-up(lg) {
    position: absolute;
  }
}

.graph-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  padding: 2rem 1rem 0 1rem;

  @include media-breakpoint-up(xl) {
    padding: 44px 50px 0 0;
  }

  @media (min-width: 2000px) {
    overflow-x: hidden;
  }

  &__item:last-child .dashboard-graph {
    border-bottom: 0;
  }

  &__item {
    max-width: 600px;
    margin: 0 auto;
  }

  &__wrap {
    @media (min-width: 2000px) {
      transform: scale(1.1);
    }

    @media (min-width: 3000px) {
      transform: scale(1.4);
    }
  }
}

.view-graphs .graph-section {
  @media (min-width: 2000px) {
    padding: 44px 200px 0 200px;
  }

  @media (min-width: 3000px) {
    padding: 44px 500px 0 500px;
  }

  .graph-section__wrap {
    @media (min-width: 2000px) {
      transform: scale(1.3);
    }

    @media (min-width: 3000px) {
      transform: scale(1.7);
    }
  }
}

.fullscreen-button {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  .btn {
    color: white;
    position: absolute;
  }
}

@include media-breakpoint-up(lg) {
  .circle-container li:nth-child(8),
  .circle-container li:nth-child(11) {
    .circle-stat__wrap {
      margin-top:  -0.5rem;
    }
  }
}
